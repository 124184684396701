import React, { createContext, useContext, useState, useEffect } from 'react';
import editAcount from '../Api/User/editAcount'

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    
    const initialUser = {
        data: {
            id: '',
            alias: '',
            login: '',
            permissions: '',
            photoPath: '',
            lastName: '',
            firstName: '',
            middleName: '',
            address: '',
            phoneNumber: '',
            roles: '',
            passportData: {
                passportNumber: '',
                passportIssuedBy: '',
                passportIssueDate: '',
            },
        },
        token: '',
        isLoggedIn: false,
    };

    const [user, setUser] = useState(initialUser);

    useEffect(() => {
        // Получение данных пользователя из Local Storage при загрузке
        const userDataJSON = localStorage.getItem('userData');
        if (userDataJSON) {
            const userData = JSON.parse(userDataJSON);
            // Проверка passportData на null и установка значений по умолчанию
            const updatedPassportData = userData.data.passportData || {
                passportNumber: '',
                passportIssuedBy: '',
                passportIssueDate: '',
            };
            const updatedUser = {
                ...initialUser,
                isLoggedIn: true,
                token: userData.token,
                data: {
                    ...userData.data,
                    passportData: updatedPassportData,
                },
            };
            setUser(updatedUser);
        }
    }, []); // Этот эффект запустится только при монтировании компонента

    const login = (userData, token) => {
        setUser({
            isLoggedIn: true,
            token: token,
            data: userData,
        });
        localStorage.setItem('userData', JSON.stringify({ data: userData, token, isLoggedIn: true }));

    };

    const logout = () => {
        setUser({
            isLoggedIn: false,
            token: '',
            data: null,
        });
        localStorage.removeItem('userData');
    };
    const updateUserData = (newUserData) => {
        setUser({
            ...user,
            data: {
                ...user.data,
                ...newUserData,
            },
        });
    };
        const sendEditRequest = async (newUser) => {
            const newdata = await editAcount(newUser);
            const updatedUser = await {
                ...initialUser,
                isLoggedIn: true,
                token: user.token,
                data: newdata
            };
            setUser(updatedUser);
            localStorage.setItem('userData', JSON.stringify({ data: newdata, token: user.token, isLoggedIn: true }));
        };

    return (
        <UserContext.Provider value={{ user, setUser, login, logout, updateUserData, sendEditRequest }}>
            {children}
        </UserContext.Provider>
    );
};
